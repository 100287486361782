import React from "react";
import { Link } from "gatsby"
import ImageModule from "gatsby-theme-starberry-lomondgroup/src/modules/image_module";
import { PageLinks } from "../../common/page-links";
import "gatsby-theme-starberry-lomondgroup/src/components/InternationalBox/InternationalBox.scss";

const InternationalBox = (props) => {
    const BoxList = props.BoxList;

    const ImageRenderList = ({item, imagename}) => {
        let processedImages = JSON.stringify({});
        if (item?.imagetransforms?.image_Transforms) {
            processedImages = item?.imagetransforms?.image_Transforms;
        }
        return (
          <ImageModule ImageSrc={item?.banner_section?.banner_image} title={item.name} altText={item.name} imagetransforms={processedImages} ggfx_results={item?.ggfx_results} renderer="srcSet" imagename={imagename} strapi_id={item.id} />                           
        )
    }

    return (
        <React.Fragment>
            <div className="international-address-box">
                <div className="box">
                    <Link to={`/${PageLinks.international}/${BoxList.slug}/`}>
                        <ImageRenderList item={BoxList} imagename={"office.image.tile_image"} />
                    </Link>
                </div>
                <div className="box-content">
                    <div className="top-info">
                        <Link to={`/${PageLinks.international}/${BoxList.slug}/`}>
                            {BoxList?.title}
                            <i className="icon-right"></i>
                        </Link>
                    </div>
                   
                    <address>{BoxList?.banner_section?.content}</address>
                </div>
            </div>
        </React.Fragment>
    );
};
export default InternationalBox;
