import React, { useState, useEffect } from "react";
import { Link } from "gatsby"
import loadable from "@loadable/component"
//import Select from 'react-select';
import Slider from "react-slick";
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from "gatsby"
import "gatsby-theme-starberry-lomondgroup/src/components/International/International.scss";
import InternationalBox from "../InternationalBox/InternationalBox"
import ContentModule from "gatsby-theme-starberry-lomondgroup/src/modules/content_module";

const International = ({Module, slider_count, id}) => {

    const data = useStaticQuery(graphql`
        query international{
            glstrapi {
                internationalLocations {
                    title
                    id
                    slug
                    ggfx_results {
                        id
                        content_type
                        transforms
                        src_import_url
                        src_cftle
                        field
                    }
                    banner_section {
                        banner_image {
                            url
                            alternativeText
                        }
                        content
                    }
                }
            }
        }
    `)

    var international_data = data.glstrapi.internationalLocations;

    //console.log("test_address ==>", test_address.indexOf("Marina"), office_list)

    var settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: slider_count || 4,
        slidesToScroll: 1,
        mobileFirst: true,
        // afterChange: (event) => {
        //     var activeDotEl = $('.features .slick-slider').find('.slick-dots').find('li.slick-active');
        //     if(activeDotEl.get(0)){
        //         activeDotEl.get(0).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        //     }
            
        // },
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    dots: false,
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    dots: true,
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    dots:true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    if(id){
        international_data = international_data.filter(item => item.id !== id);
    }

    return (
        <React.Fragment>
            <section className={`international-block`}>
                <Container>
                    <Row>
                        <Col>      
                            {Module?.title_content ? 
                                <ContentModule Content={Module?.title_content} /> 
                                :
                                (Module.title && <h3>{Module.title}</h3>)
                            }                      
                            {Module.description && <ContentModule Content={Module.description} />}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {international_data?.length > 0 && 
                            // <div className={`international-slider ${slider_count ? "" : "no_slider" }`}>
                            <div className={`international-slider`}>
                                {/* {slider_count ? 
                                    <Slider {...settings}>
                                        {international_data.map((item, index) => (
                                            <InternationalBox key={index} BoxList={item} />
                                        ))}
                                    </Slider>
                                :
                                <>
                                    {international_data.map((item, index) => (
                                        <InternationalBox key={index} BoxList={item} />
                                    ))}
                                </>
                                } */}
                                <Slider {...settings}>
                                    {international_data.map((item, index) => (
                                        <InternationalBox key={index} BoxList={item} />
                                    ))}
                                </Slider>
                                
                            </div>
                            }
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};
export default International;
